import {Image, Button, Container, Row, Col, Form} from 'react-bootstrap';
import {signOut} from "../App";
import React from "react";
import { Link } from "react-router-dom";

const ProjectManagerPage = () => {

    const buttonStyle = {
        color: 'white',
        backgroundColor: 'plum',
        paddingLeft: 7,
        paddingRight: 7,
        height: 32,
        borderRadius: 2,
        justifyContent: 'center',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
        marginRight: 4
    };
    return (
        <Container>
            <Container
                style={{backgroundColor:'#fff', height:44}}>
                <Row style={{flexDirection:'row', display:'flex', justifyContent:'space-between', alignItems:'center', paddingLeft:10, paddingRight:7, paddingTop:4,
                    borderBottom:1, borderBottomColor:'#efefef', borderBottomStyle:'solid'}}>
                    <Col><Image style={{width: 100, height: 35}}
                                src={require('../images/smallLogoPurple.png')}/></Col>
                    <Col><Container style={{alignItems:'center', flexDirection:'row', marginRight:7, paddingBottom:4}}>
                        <Link to="/" style={{textDecorationLine:'none'}}>
                        <Button style={buttonStyle}>MAIN</Button>
                            <Button style={buttonStyle} onClick={signOut}>LOGOUT</Button>
                        </Link>
                    </Container></Col>
                </Row>
            </Container>

            <Container style={{alignItems:'center', flexDirection:'column', display:'flex'}} >
                <Form.Label style={{ fontSize: 30, fontFamily:'system-ui', fontWeight: 'bold', paddingTop: 30, paddingBottom: 30}}>Projects management</Form.Label>
                <Link to="/newproject" style={{textDecorationLine:'none'}}>
                <Button style={{
                    marginTop: 20, ...buttonStyle
                }}
                        >ADD NEW PROJECT</Button>
                </Link>
                <Link to="/delete" style={{textDecorationLine:'none'}}>
                <Button style={{
                    marginTop: 20, ...buttonStyle
                }}
                >DELETE PROJECT</Button>
                </Link>
            </Container>
        </Container>
    );
}
export {
    ProjectManagerPage
}
