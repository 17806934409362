import React, {useState} from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Container} from "react-bootstrap";
import BackImage from './images/background.png';
import {ProjectManagerPage} from "./components/ProjectManagerPage";
import {NewProjectPage} from "./components/NewProjectPage";
import {DeleteProjectPage} from "./components/DeleteProjectPage";
import {LoggedInPage} from "./components/LoggedInPage";
import {LoginPage} from "./components/LoginPage";

export let signOut;

function App() {
    const [id, setId] = useState("")
    const [firstName, setFirstName] = useState("")

    signOut = React.useCallback(() => {
        try {
            sessionStorage.removeItem('userInfo');
            setId("");
            setFirstName("");
        } catch (e) {
            console.log("error", e)
        }

    }, []);

    const isSignedIn = !!id;

    return (
            <Container style={{
                 flexDirection:'column', display:'flex', backgroundSize:'contain',
                 backgroundImage: `url(${BackImage})`, height: '100vh'
            }}>
                { isSignedIn ? (
                    <BrowserRouter>
                        <Routes>
                            <Route index path="/" element={<LoggedInPage id={id} logOutName={firstName}/>}/>
                            <Route path="/manage" element={<ProjectManagerPage/>}/>
                            <Route path="/newproject" element={<NewProjectPage/>}/>
                            <Route path="/delete" element={<DeleteProjectPage/>}/>
                        </Routes>
                    </BrowserRouter>) : <LoginPage  signFirstName={setFirstName}  signId={setId}
                />
                }
            </Container>
    )
}

export default App;
