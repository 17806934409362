import {Image, Button, Form, Container, Row, Col} from 'react-bootstrap';
import React, {useState} from "react";
import {submitNewProject} from '../api/ApiRequest';
import {signOut} from "../App";
import { Link } from "react-router-dom";

const NewProjectPage = () => {

    const buttonStyle = {
        color: 'white',
        backgroundColor: 'plum',
        paddingLeft: 7,
        paddingRight: 7,
        height: 32,
        borderRadius: 2,
        justifyContent: 'center',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
        marginRight: 4
    };
    const [projectId, setProjectId] = useState('');
    const [project, setProject] = useState('');

    const handleChangePr = (event:any) => {
        const result = event.target.value.toUpperCase();
        setProject(result);
    };

    const handleChangeId = (event: { target: { value: string; }; }) => {
        const result = event.target.value.toUpperCase();
        setProjectId(result);
    };

    const submitHandler = () => {
        submitNewProject({projectId: projectId, projectName: project}).then((response) => {
            if (response && response.code === "400") {
                alert(response.message);
            } else {
                alert("Project successfully added to project list!");
                window.open("/", '_parent')
            }
        })
    };

    return (
        <Container>
            <Container
            style={{backgroundColor:'#fff', height:44}}>
                <Row style={{flexDirection:'row', display:'flex', justifyContent:'space-between', alignItems:'center', paddingLeft:10, paddingRight:7, paddingTop:4,
                    borderBottom:1, borderBottomColor:'#efefef', borderBottomStyle:'solid'}}>
                    <Col><Image style={{width: 100, height: 35}}
                                src={require('../images/smallLogoPurple.png')}/></Col>
                    <Col><Container style={{alignItems:'center', flexDirection:'row', marginRight:7, paddingBottom:4}}>
                        <Link to="/" style={{textDecorationLine:'none'}}>
                            <Button style={buttonStyle}>MAIN</Button>
                            <Button style={buttonStyle} onClick={signOut}>LOGOUT</Button>
                        </Link>
                    </Container></Col>
                </Row>
            </Container>
            <Container style={{alignItems:'center', flexDirection:'column', display:'flex'}} >
                <Form.Label style={{ fontSize: 30, fontFamily:'system-ui', fontWeight: 'bold', paddingTop: 30, paddingBottom: 30}}>Add a new
                    project</Form.Label>
                <Form.Label style={{ fontSize: 14, fontFamily:'system-ui'}}>Project name</Form.Label>
                <Form.Control  style={{ width:180, borderColor: "gray", borderWidth: 1, borderRadius: 10, padding: 8, marginTop:5, fontFamily:'system-ui'}}
                               type="text"
                               onChange={handleChangePr}
                               value={project}
                               autoCapitalize={"characters"}
                               placeholder="Enter new project name" />
                <Form.Label style={{paddingTop:10, fontSize: 14, fontFamily:'system-ui'}}>Project ID</Form.Label>
                <Form.Control  style={{  width:180, borderColor: "gray", borderWidth: 1, borderRadius: 10, padding: 8, marginTop:5, fontFamily:'system-ui'}}
                               type="text"
                               onChange={handleChangeId}
                               value={projectId}
                               maxLength={2}
                               autoCapitalize={"characters"}
                               placeholder="Enter new projectID" />
                <Button style={{
                    marginTop: 20, ...buttonStyle
                }}
                        onClick={submitHandler}>ADD NEW PROJECT</Button>
                <Link to="/manage" style={{textDecorationLine:'none'}}>
                <Button style={{
                    marginTop: 5, ...buttonStyle
                }}
                >BACK</Button>
                </Link>
            </Container>
        </Container>
    )
}

export {
    NewProjectPage
}
