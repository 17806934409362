import {signOut} from "../App";

function getAccessToken() {
    let userInfoJson = sessionStorage.getItem('userInfo');
    return userInfoJson ? JSON.parse(userInfoJson).accessToken : "";
}

const post = (endpoint: string, data: any = null) => {
    const accessToken = getAccessToken();
    return fetch( "/api/" +endpoint, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: data
    }).then(processResponse)
        .then(processResult)
        .then((responseData: string) => {
            if (responseData) return JSON.parse(responseData);
        })
        .catch((error) => {
            console.error(error);
            return 'Did not get response from server';
        })

};

const get = (endpoint: string, param: any = '') => {
    const accessToken = getAccessToken();
    return fetch( "/api/" + endpoint + param, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(processResponse)
        .then(processResult)
        .then(JSON.parse)
        .catch((error) => {
            console.error(error);
            return 'Did not get response from server';
        });
};

function processResponse(response) {
    return response.text().then(text => ({
        status: response.status,
        data: text
    }));
}

function processResult(response) {
    if (response.status === 401) {
        signOut()
        throw new Error('response.status: ' + response.status);
    }
    if (response.status >= 500 || response.status === 404) {
        throw new Error('response.status: ' + response.status);
    }
    return response.data;
}

const removeDay = async (data: { date: string, googleID: string }) => {
    await post('removeDay', JSON.stringify(data));
};

const submitDay = async (data: { date: string, googleID: string, dayType: string, projectID: string, description: string }) => {
    await post('submitDay', JSON.stringify(data));
};

const submitAbsenceDaysToGoogle = async (data: { dates: string[], googleID: string, dayType: string }) => {
    await post('submitAbsenceDaysToGoogle', JSON.stringify(data));
};

const sendUser = async (data: { firstName: string, lastName: string, googleID: string, email: string }) => {
    await post('sendUser', JSON.stringify(data));
};

const checkIfVacationDayIsAllowed = async (data: { userID: string, requestedVacationDates: string[] }) => {
    return await post('isVacationDayAllowed', JSON.stringify(data));
};

const submitNewProject = async (data: { projectId: string, projectName: string }) => {
    return await post('project', JSON.stringify(data));
};

const submitSelectedProject = async (data: { projectID: string, googleID: string }) => {
    await post('selectProject', JSON.stringify(data));
};

const deleteProject = async (projectId) => {
    await post('deleteProject/' + projectId);
};

const getCalendarDays = async (userId) => {
    return await get('getCalendarDays/' + userId);
};

const getUserName = async (userId) => {
    return await get('getUserName/' + userId);
};

const getSelectedProject = async (userId) => {
    return await get('selectedProject/' + userId);
};

const getUsers = async () => {
    return await get('users');
};

const getProjects = async () => {
    return await get('projects');
};

const getUnusedProjects = async () => {
    return await get('emptyProjects');
};

const getUnusedVacationDays = async (userID) => {
    return await get('remainingVacationDays/' + userID);
};

export {
    post,
    get,
    removeDay,
    submitAbsenceDaysToGoogle,
    sendUser,
    getUsers,
    getProjects,
    submitSelectedProject,
    getSelectedProject,
    submitDay,
    getUnusedVacationDays,
    checkIfVacationDayIsAllowed,
    submitNewProject,
    deleteProject,
    getUnusedProjects,
    getUserName,
    getCalendarDays
};

