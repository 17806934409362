import {Image, Button, Container, Row, Col, Form} from 'react-bootstrap';
import React, {useEffect, useState} from "react";
import {deleteProject, getUnusedProjects} from '../api/ApiRequest';
import {signOut} from "../App";
import {Link} from "react-router-dom";

const DeleteProjectPage = () => {

    const buttonStyle = {
        color: 'white',
        backgroundColor: 'plum',
        paddingLeft: 7,
        paddingRight: 7,
        height: 32,
        borderRadius: 2,
        justifyContent: 'center',
        fontWeight: 'bold',
        border: 'none',
        cursor: 'pointer',
        marginRight: 4
    };

    const [projectId, setProjectId] = useState('');
    const [unusedProjects, setUnusedProjects] = useState([] as any[]);

    useEffect(() => {
        getUnusedProjects().then((res) => {
            setUnusedProjects(res);
            if (res && res.length) {
                setProjectId(res[0].projectId)
            }
            console.log(res)
        });
    }, []);

    const deleteHandler = () => {
        deleteProject(projectId)
        alert("Project successfully deleted from project list!");
        window.open("/", '_parent')
    };

    return (
        <Container>
            <Container
                style={{backgroundColor: '#fff', height: 44}}>
                <Row style={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: 10,
                    paddingRight: 7,
                    paddingTop: 4,
                    borderBottom: 1,
                    borderBottomColor: '#efefef',
                    borderBottomStyle: 'solid'
                }}>
                    <Col><Image style={{width: 100, height: 35}}
                                src={require('../images/smallLogoPurple.png')}/></Col>
                    <Col><Container
                        style={{alignItems: 'center', flexDirection: 'row', marginRight: 7, paddingBottom: 4}}>
                        <Link to="/" style={{textDecorationLine:'none'}}>
                            <Button style={buttonStyle}>MAIN</Button>
                            <Button style={buttonStyle} onClick={signOut}>LOGOUT</Button>
                        </Link>
                    </Container></Col>
                </Row>
            </Container>
            <Container style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <Form.Label style={{
                    fontSize: 30,
                    fontFamily: 'system-ui',
                    fontWeight: 'bold',
                    paddingTop: 30,
                    paddingBottom: 30
                }}>Delete project</Form.Label>
                <Container>
                    {unusedProjects.length > 0 && (
                        <Container>
                            <Container style={{marginBottom: 10, alignItems: 'center'}}>
                                <Form.Label style={{textAlign: 'center', fontSize: 15, fontFamily: 'system-ui'}}>Only
                                    not assigned projects are shown</Form.Label>
                            </Container>
                            <Container style={{alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                                <select
                                    style={{
                                        borderColor: 'purple',
                                        borderWidth: 1,
                                        borderRadius: 10,
                                        fontSize: 17,
                                        width: 190,
                                        padding: 3
                                    }}
                                    value={projectId}
                                    onChange={(picked) => setProjectId(picked.target.value)}>
                                    {unusedProjects.map(project => <option value={project.projectId}
                                                                           label={project.projectName}
                                                                           key={project.projectId}/>
                                    )}
                                </select>
                            </Container>
                        </Container>
                    )}
                </Container>
                <Container>
                    {unusedProjects.length > 0 && (
                        <Button style={{
                            marginTop: 20, ...buttonStyle
                        }}
                                onClick={deleteHandler}>DELETE PROJECT</Button>
                    )}
                </Container>
                <Container>
                    {unusedProjects.length === 0 && (
                        <Container>
                            <Form.Label
                                style={{padding: 20, textAlign: 'center', fontSize: 15, fontFamily: 'system-ui'}}>No
                                projects to delete
                                currently</Form.Label>
                        </Container>
                    )}
                </Container>
                <Link to="/manage" style={{textDecorationLine: 'none'}}>
                    <Button style={{
                        marginTop: 5, ...buttonStyle
                    }}
                    >BACK</Button>
                </Link>
            </Container>
        </Container>
    )
}

export {
    DeleteProjectPage
}
