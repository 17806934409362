import React, {useEffect, useState} from "react";
import "../styles/modalStyle.css";
import moment from "moment";
import {
    submitDay,
    submitAbsenceDaysToGoogle,
    checkIfVacationDayIsAllowed, getCalendarDays, removeDay,
} from '../api/ApiRequest';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from 'date-fns/locale/en-GB';
import Modal from 'react-modal';
import {Button, Container, Form} from "react-bootstrap";


registerLocale('en', en)

const customStyles = {
    overlay: {
        zIndex: 6666,
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


const AbsenceDialog = ({
                           workDates,
                           setWorkDates,
                           projectId,
                           setProjectId,
                           extraVacationAllowed,
                           remainingExtraVacationDays,
                           dayType,
                           setDayType,
                           onModalClose,
                           isDialogOpen,
                           id,
                           selectedStartDate,
                           selectedEndDate,
                           projects,
                           setEventsDB
                       }) => {

    const [absenceStartDate, setAbsenceStartDate] = useState("");
    const [absenceEndDate, setAbsenceEndDate] = useState("");
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [comment, setComment] = useState('');

    useEffect(() => {
        setAbsenceStartDate(selectedStartDate)
        setAbsenceEndDate(selectedEndDate)
    }, [selectedStartDate, selectedEndDate]);

    useEffect(() => {
        setIsDialogVisible(isDialogOpen)
    }, [isDialogOpen])

    const handleChangeCo = comment => {
        const result = comment.target.value.toUpperCase();
        setComment(result);
    };

    const addToArray = (date, object) => {
        object[date] = {
            customStyles: {}
        }
        setWorkDates({
            ...workDates, ...object
        })
    }

    const submitHandler = () => {
        if (new Date(absenceStartDate) > new Date(absenceEndDate)) {
            alert('End date cannot be before start date')
            return;
        }
        let startDate = new Date(absenceStartDate)
        let today = new Date();
        let firstOfPrevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        if ((today.getDate() > 10 && daysFromDate(startDate) >= today.getDate()) || (startDate < firstOfPrevMonth)) {
            alert("Selected start date is not allowed");
            return;
        }
        const dateArray = periodHandler(absenceStartDate, absenceEndDate);
        if (dayType === 'PAID_VACATION') {
            checkIfVacationDayIsAllowed({
                userID: id,
                requestedVacationDates: dateArray
            }).then((vacationAllowedResponse) => {
                console.log("whole response is " + vacationAllowedResponse + " just bool is " + vacationAllowedResponse.vacationAllowed)
                if (!vacationAllowedResponse.vacationAllowed) {
                    alert(vacationAllowedResponse.alertMessage)
                    return
                } else {
                    submitSelectedDays(dateArray)
                    cancelHandler()
                }
            });
        } else if (dayType === 'EXTRA_VACATION' && dateArray.length > remainingExtraVacationDays) {
            alert('Not enough extra vacation days left')
            return;
        } else if (dayType === 'WORK' && projectId === '00') {
            alert('Please select a project before submitting work day')
            return
        } else {
            submitSelectedDays(dateArray)
            cancelHandler()
        }
    }

    const submitSelectedDays = (dateArray) => {
        let object = {}
        dateArray.forEach((date) => {
            if (workDates.hasOwnProperty(date)
            ) {
                removeDay({"date": date, googleID: id}).then(() => {
                    submitDay({
                        "date": date,
                        googleID: id,
                        dayType: dayType,
                        projectID: dayType !== 'WORK' ? '00' : projectId,
                        description: comment
                    }).then(() => getCalendarDays(id).then((res) => {
                        setEventsDB(res)
                    }))
                    addToArray(date, object)
                })
            } else {
                submitDay({
                    "date": date,
                    googleID: id,
                    dayType: dayType,
                    projectID: dayType !== 'WORK' ? '00' : projectId,
                    description: comment
                }).then(() => getCalendarDays(id).then((res) => {
                    setEventsDB(res)
                }))
                addToArray(date, object)
            }
        })
        if (dayType !== 'WORK') {
            submitAbsenceDaysToGoogle({'dates': dateArray, googleID: id, dayType: dayType});
        }
        setComment("");
    }

    const daysFromDate = (pickedDate) => {
        var oneDay = 1000 * 60 * 60 * 24;
        let today = new Date();
        let firstDate = new Date(pickedDate.getFullYear(), pickedDate.getMonth(), pickedDate.getDate(), 0, 0, 0, 0)
        today.setHours(0, 0, 0, 0);
        return Math.round((today.getTime() - firstDate.getTime()) / (oneDay));
    }
    const periodHandler = (startDate, endDate) => {
        var dateArray: string[] = [];
        var currentDate = moment(startDate);
        var stopDate = moment(endDate);
        while (currentDate.format("YYYY-MM-DD") <= stopDate.format("YYYY-MM-DD")) {
            dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    const cancelHandler = () => {
        setIsDialogVisible(false)
        setComment("");
        onModalClose()
    }

    return (
        <div>
            <Modal
                isOpen={isDialogVisible}
                onRequestClose={() => cancelHandler()}
                style={customStyles}
                contentLabel="Example Modal"
                closeTimeoutMS={200}
            >
                <Container
                    style={{height: '100%', width: '100%'}}>
                    <Container
                        style={{flexDirection: 'row', justifyContent: 'flex-start', paddingBottom: 7}}>
                        <Form.Label style={{
                            paddingTop: 20,
                            textAlign: 'center',
                            fontSize: 18,
                            fontFamily: 'system-ui',
                            fontWeight: 'bold'
                        }}>Edit Day:</Form.Label>
                    </Container>
                    <Container style={{
                        width: '97%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 30,
                        paddingBottom: 5,
                        paddingTop: 5,
                        marginTop: 5,
                        zIndex: 2
                    }}>
                        <Container>
                            <Form.Label
                                style={{paddingTop: 20, textAlign: 'center', fontSize: 18, fontFamily: 'system-ui'}}>Start
                                date:</Form.Label>
                        </Container>
                        <Container style={{marginLeft: 15}}>
                            <DatePicker
                                dateFormat="dd / MM / yyyy"
                                selected={new Date(absenceStartDate)}
                                onChange={(date) => setAbsenceStartDate(String(date))}
                                locale="en"
                                showWeekNumbers
                                selectsStart
                                startDate={new Date(absenceStartDate)}
                                endDate={new Date(absenceEndDate)}
                                todayButton="Today"
                                className='date-input-field'
                            />
                        </Container>
                    </Container>
                    <Container style={{
                        width: '97%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingLeft: 30,
                        paddingBottom: 5,
                        paddingTop: 5,
                        zIndex: 1
                    }}>
                        <Container>
                            <Form.Label
                                style={{paddingTop: 20, textAlign: 'center', fontSize: 18, fontFamily: 'system-ui'}}>End
                                date:</Form.Label>
                        </Container>
                        <Container style={{marginLeft: 24}}>
                            <DatePicker
                                dateFormat="dd / MM / yyyy"
                                selected={new Date(absenceEndDate)}
                                onChange={(date) => setAbsenceEndDate(String(date))}
                                locale="en"
                                showWeekNumbers
                                selectsEnd
                                startDate={new Date(absenceStartDate)}
                                endDate={new Date(absenceEndDate)}
                                minDate={new Date(absenceStartDate)}
                                todayButton="Today"
                                className='date-input-field'
                            />
                        </Container>
                    </Container>
                    <Container
                        style={{
                            width: '100%',
                            paddingTop: 15,
                            paddingLeft: 30,
                            paddingBottom: 5
                        }}>
                        <Container style={{
                            paddingBottom: 10
                        }}>
                            <Form.Label style={{
                                fontSize: 18,
                                paddingBottom: 10, fontFamily: 'system-ui'
                            }}>Choose an absence type:</Form.Label>
                        </Container>
                        <Container style={{
                            paddingBottom: 5,
                            marginLeft: -5
                        }}>
                            <select
                                style={{
                                    borderColor: 'purple',
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    fontSize: 17,
                                    width: 215,
                                    fontFamily: 'system-ui',
                                    padding: 2
                                }}
                                value={dayType}
                                onChange={(event) => setDayType(event.target.value)}>
                                <option label="Work" value="WORK"/>
                                <option label="Paid vacation" value="PAID_VACATION"/>
                                <option label="Sick leave" value="SICK_LEAVE"/>
                                <option label="Care leave" value="CARE_LEAVE"/>
                                <option label="Study leave" value="STUDY_LEAVE"/>
                                <option label="Unpaid vacation" value="UNPAID_VACATION"/>
                                {extraVacationAllowed &&
                                    <option label="Extra Vacation (3 years of working)" value="EXTRA_VACATION"/>}
                                <option label="Other" value="OTHER"/>
                            </select>
                        </Container>
                        {dayType === 'WORK' &&
                            <Container style={{
                                marginLeft: -5
                            }}>
                                <select
                                    style={{
                                        borderColor: 'purple',
                                        borderWidth: 1,
                                        borderRadius: 10,
                                        fontSize: 17,
                                        width: 215,
                                        fontFamily: 'system-ui',
                                        padding: 2
                                    }}
                                    value={projectId}
                                    onChange={(picked) => setProjectId(picked.target.value)}>
                                    {projects.map(project => <option key={project.projectId} value={project.projectId}
                                                                     label={project.projectName}/>
                                    )}
                                </select>
                            </Container>
                        }
                    </Container>
                    <Container style={{
                        width: '100%',
                        paddingTop: 10,
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingBottom: 15,
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <Form.Label style={{
                            fontSize: 18,
                            paddingBottom: 10,
                            alignItems: 'center'
                        }}>Comment</Form.Label>
                        <Container style={{marginLeft: -5}}>
                            <Form.Control
                                value={comment}
                                onChange={handleChangeCo}
                                maxLength={100}
                                as="textarea"
                                placeholder="Max 100 characters"
                                style={{
                                    paddingLeft: 5,
                                    width: 215,
                                    height: 100,
                                    right: 10,
                                    borderColor: 'purple',
                                    fontFamily: 'system-ui',
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    resize: 'none'
                                }}
                            />
                        </Container>
                    </Container>
                    <Container style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        paddingBottom: 30
                    }}>
                        <Container>
                            <Button style={{
                                color: 'white',
                                backgroundColor: 'lightgrey',
                                paddingLeft: 7,
                                paddingRight: 7,
                                height: 32,
                                borderRadius: 2,
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                                    onClick={() => cancelHandler()}
                            >CANCEL</Button>
                        </Container>
                        <Container>
                            <Button style={{
                                color: 'white',
                                backgroundColor: 'plum',
                                paddingLeft: 7,
                                paddingRight: 7,
                                height: 32,
                                borderRadius: 2,
                                justifyContent: 'center',
                                fontWeight: 'bold',
                                border: 'none',
                                cursor: 'pointer'
                            }}
                                    onClick={() => submitHandler()}
                            >SUBMIT</Button>
                        </Container>
                    </Container>
                </Container>
            </Modal>
        </div>
    )
}

export {
    AbsenceDialog
}
