import {slide as Menu} from 'react-burger-menu'
import React, {useEffect, useState} from "react";
import {signOut} from "../App";
import "../styles/menuStyle.css";
import {getUsers} from "../api/ApiRequest";
import {Button, Container, Form} from 'react-bootstrap';
import { Link } from "react-router-dom";

const Sidebar = (props) => {
    const [users, setUsers] = useState<any[]>([]);

    const [isHover, setIsHover] = useState(0);

    useEffect(() => {
        getUsers().then((res) => {
            res.sort((first, second) => first.name.localeCompare(second.name))
            setUsers(res);
        });
    }, []);

    const handleSelection = (itemValue) => {
        props.sendData(itemValue)
    };

    const handleMouseEnter = (props) => {
        setIsHover(props);
    };

    const handleMouseLeave = () => {
        setIsHover(0);
    };
    const userInfo = sessionStorage.getItem("userInfo");
    const email = userInfo ? JSON.parse(userInfo).email : "";
    return (
       <Menu right width={240}>
            <Container >
                <select
                    style={{ borderColor: 'purple',
                        borderWidth: 1,
                        borderRadius: 10,
                        fontSize: 17,
                        width:190,
                        fontFamily:'system-ui',
                        padding:2}}
                    value={props.id}
                    onChange={(itemValue)=>handleSelection(itemValue.target.value)}>
                    {users.map(user => <option value={user.googleID} label={user.name.toUpperCase()} key={user.googleID}/>
                    )}
                </select>
            </Container>
            <a className="menu-item"
               href={ "https://calendar.google.com/calendar/u/" + email + "/embed?src=ch7hqgj67ql92r5pdrlc0sc8dc@group.calendar.google.com&ctz=Europe/Tallinn"}
               target="_blank" rel="noreferrer"
               onMouseEnter={() => handleMouseEnter(2)}
               onMouseLeave={handleMouseLeave}>
                <Form.Label style={{color: isHover === 2 ? 'black' : 'white', fontSize: 15, fontWeight: 500, fontFamily:'system-ui', cursor:'pointer'}}
                >CALENDAR</Form.Label>
            </a>
            <a className="menu-item"
               href={'/report?month=' + props.monthNr + '&year=' + props.yearNr}
               target="_blank" rel="noreferrer"
               onMouseEnter={() => handleMouseEnter(1)}
               onMouseLeave={handleMouseLeave}>
                <Form.Label style={{color: isHover === 1 ? 'black' : 'white', fontSize: 15, fontWeight: 500, fontFamily:'system-ui', cursor:'pointer'}}
                >MONTH REPORT</Form.Label>
            </a>
            <a className="menu-item"
               href={'/ocureport?year=' + props.yearNr}
               target="_blank" rel="noreferrer"
               onMouseEnter={() => handleMouseEnter(5)}
               onMouseLeave={handleMouseLeave}>
                <Form.Label style={{color: isHover === 5 ? 'black' : 'white', fontSize: 15, fontWeight: 500, fontFamily:'system-ui', cursor:'pointer'}}
                >TOTAL REPORT</Form.Label>
            </a>
            <div
                onMouseEnter={() => handleMouseEnter(3)}
                onMouseLeave={handleMouseLeave}>
                <Link to="/manage" style={{textDecorationLine:'none'}}>
                    <Form.Label
                        style={{
                            color: isHover === 3 ? 'black' : 'white',
                            alignItems: 'flex-start',
                            fontSize: 15,
                            fontWeight: 500,
                            fontFamily:'system-ui',
                            cursor:'pointer',
                        }}
                    >PROJECT MANAGEMENT</Form.Label>
                </Link>
            </div>
           <Button
               onMouseEnter={() => handleMouseEnter(4)}
               onMouseLeave={handleMouseLeave}
               style={{
                   backgroundColor:'#b770a1',
                   color: isHover === 4 ? 'black' : 'white',
                   borderColor: isHover === 4 ? 'black' : 'white',
                   fontSize: 15,
                   borderStyle: 'solid',
                   fontFamily:'system-ui',
                   borderWidth: 1,
                   padding: 5,
                   fontWeight: 500,
                   cursor:'pointer',
                   marginTop: 50
               }}
               onClick={signOut}>LOGOUT  {props.logOutName.toUpperCase()}</Button>
        </Menu>
    )
}
export {
    Sidebar
}
