import {get} from "./ApiRequest";

const fetchDays = async (workDates, setWorkDates, id) => {
    try {
        const param = "?googleID=" + id
        const response = await get("existingDays", param);
        let object = {};
        response.map( (day) => {
            let dayString = day.date
            return object[dayString] = {
                "customStyles":  {}
            };
        })
        setWorkDates({
            ...workDates, ...object
        })
    } catch (e) {
        console.log(e);
    }
}

export {
    fetchDays
}
