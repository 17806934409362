import {Container, Image, Form} from 'react-bootstrap';
import React from 'react';
import {useGoogleLogin} from "react-google-login";
import {sendUser} from "../api/ApiRequest";

const clientId = '890954532297-547rr7t1fg49d6ggu7qnpmn6j6a7oh5u.apps.googleusercontent.com';

const LoginPage = props => {

    function setUserInfoProps(userInfo) {
        props.signId(userInfo.signId)
        props.signFirstName(userInfo.signFirstName)
    }

    const onSuccess = (res) => {
        let userInfo = {
            signFirstName: res.profileObj.givenName,
            signId: res.googleId,
            accessToken: res.accessToken,
            email: res.profileObj.email
        };
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
        setUserInfoProps(userInfo);
        let user = {
            firstName: res.profileObj.givenName,
            lastName: res.profileObj.familyName,
            googleID: res.googleId,
            email: res.profileObj.email
        }
        sendUser(user)
    };

    let userInfoJson = sessionStorage.getItem("userInfo");
    if (userInfoJson) {
        setUserInfoProps(JSON.parse(userInfoJson))
    }

    const {signIn} = useGoogleLogin({
        onSuccess,
        clientId,
        isSignedIn: false,
        accessType: 'offline',
    });

    return (
        <Container style={{
            flex: 3,
            alignItems: 'center',
            justifyContent: 'center', display: 'flex'
        }}>
            <Container style={{
                width: 300,
                height: 300,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                backgroundColor: 'rgba(230,230,250, 0.6)',
                borderRadius: 20
            }}>
                <Image style={{width: 130, height: 100, marginBottom: 40}}
                       src={require('../images/largeLogoPurple.png')}/>

                <Container
                    onClick={signIn}>
                    {<Form.Label style={{
                        backgroundColor: 'white',
                        borderColor: '#CF4C3C',
                        borderWidth: 1,
                        borderRadius: 12,
                        borderStyle: 'solid',
                        color: '#CF4C3C',
                        cursor: 'pointer',
                        fontFamily: 'system-ui',
                        fontSize: 20,
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        paddingTop: 19,
                        paddingLeft: 12,
                        paddingRight: 12,
                        paddingBottom: 12,
                        textAlign: 'center'
                    }}><Image style={{width: 24, height: 24}} src={require('../images/google.png')}/> Sign in with
                        Google</Form.Label>}
                </Container>
            </Container>
        </Container>
    )
}

export {
    LoginPage
}
